import { Controller } from 'stimulus';
import { animate } from '@/helpers/animations_helpers';
import { walletShortname } from '@/helpers/wallet_helpers';

const ERROR_ICON_CLASS = 'fa-exclamation-triangle';

export default class extends Controller {
  static targets = [
    'header',
    'title',
    'modal',
    'statusIcon',
    'statusText',
    'body',
    'loading',
    'dialog',
    'panel',
    'closeButton',
    'welcome',
    'sessions',
    'walletAddress',
  ];
  modal;
  modalClass;
  modalId;

  connect() {
    this.boundDialogClick = this.dialogClick.bind(this);
    this.boundEscapePress = this.escapePress.bind(this);
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundEscapePress);
    document.removeEventListener('click', this.boundDialogClick);
  }

  dialogClick = (e) => {
    if (this.panelTarget.contains(e.target)) {
      return;
    }

    this.closeModal();
  };

  escapePress = (e) => {
    if (e.key === 'Escape') {
      this.closeModal();
    }
  };

  async reopenModal(event, options = {}) {
    await this.closeModal();
    await this.openModal(event, options);
  }

  async openModal(event, options = {}) {
    if (event) {
      const openingInNewWindow =
        event.ctrlKey || event.metaKey || event.which === 2; // CTRL, Cmd, or middle mouse button was clicked
      if (openingInNewWindow) {
        return;
      }
    }

    // Initialize modal state if not already done
    if (!this.modal) {
      this.modal = { _isShown: false };
    }

    // Keep track of the last active element so we can return focus to it when the modal closes
    this.lastActiveElement = document.activeElement;

    const modalClass = event?.srcElement?.dataset?.modalClass;
    const parentModalClass =
      event?.srcElement?.parentElement.dataset?.modalClass;

    if (modalClass !== undefined || parentModalClass !== undefined) {
      this.modalClass = modalClass || parentModalClass;
      this.modalTarget.classList.add(this.modalClass);
    }

    // Don't allow the background to scroll when modal is open
    document.body.style.overflow = 'hidden';

    // Allow dialog to click close if no lockModal is set
    if (
      event?.currentTarget?.dataset?.lockModal === undefined &&
      options.lockModal === undefined
    ) {
      this.modalTarget.addEventListener('mousedown', this.boundDialogClick);
      document.addEventListener('keydown', this.boundEscapePress);
    }

    if (this.hasCloseButtonTarget) {
      if (
        event?.currentTarget?.dataset?.hideCloseButton ||
        options.hideCloseButton
      ) {
        this.closeButtonTarget.classList.add('hidden');
      } else {
        this.closeButtonTarget.classList.remove('hidden');
      }
    }

    if (options.showWelcomeModal !== undefined) {
      this.setupBody('welcome', options);
    } else if (options.showSessionsModal !== undefined) {
      this.setupBody('sessions', options);
    } else {
      this.setupBody('loading', options);
    }

    this.modal._isShown = true;

    this.modalTarget.classList.remove('hidden');
    animate(this.dialogTarget, 'enter', 'fade');

    this.modalId = event?.srcElement?.dataset?.modalId;

    // Add aria accessibility attributes
    this.modalTarget.setAttribute('aria-hidden', 'false');
    this.modalTarget.setAttribute('aria-modal', 'true');
    this.modalTarget.setAttribute('role', 'dialog');

    this.updateStatus();

    // Use MutationObserver to detect changes in the turbo frame
    const turboFrame = document.getElementById('modal_content');
    if (!turboFrame) {
      console.error('Turbo frame not found');
      return;
    }

    this.modalObserver = new MutationObserver(async (mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          if (this.panelTarget.classList.contains('hidden')) {
            await animate(this.panelTarget, 'enter', 'modal');
            this.modalObserver.disconnect(); // Disconnect observer after first content addition
          }
          break;
        }
      }
    });

    // Observe the turbo frame for child list changes
    this.modalObserver.observe(turboFrame, { childList: true, subtree: true });

    const modalBody = this.modalTarget.querySelector('.modal-body');
    if (modalBody && modalBody.children.length > 0) {
      if (this.panelTarget.classList.contains('hidden')) {
        await animate(this.panelTarget, 'enter', 'modal');
      }
    }
  }

  async closeModal(content) {
    if (content !== undefined && typeof content == 'string') {
      let modalContent;
      switch (content) {
        case 'welcome':
          modalContent = this.welcomeTarget.innerHTML;
          break;
        case 'sessions':
          modalContent = this.sessionsTarget.innerHTML;
          break;
        case 'loading':
          modalContent = this.loadingTarget.innerHTML;
          break;
      }
      if (this.bodyTarget.innerHTML !== modalContent) {
        return;
      }
    }

    // Ensure modal state is initialized
    if (!this.modal) {
      this.modal = { _isShown: false };
    } else {
      this.modal._isShown = false;
    }

    this.modalTarget.removeEventListener('mousedown', this.boundDialogClick);
    document.removeEventListener('keydown', this.boundEscapePress);

    animate(this.dialogTarget, 'leave', 'fade');
    await animate(this.panelTarget, 'leave', 'modal');

    // Toggle aria accessibility attributes
    this.modalTarget.setAttribute('aria-hidden', 'true');
    this.modalTarget.setAttribute('aria-modal', 'false');
    this.modalTarget.setAttribute('role', 'none');

    // Handle layout shift if there is a scroll bar on page
    document.body.style.overflow = 'auto';

    if (this.modalClass) {
      this.modalTarget.classList.remove(this.modalClass);
    }

    this.modalId = undefined;

    if (this.lastActiveElement) {
      this.lastActiveElement.focus();
    }

    this.onClose();
    this.modalTarget.classList.add('hidden');
    this.sessionsTarget.firstElementChild.id = '';
    this.removeModalBodyChildren();

    // Clean up the MutationObserver
    if (this.modalObserver) {
      this.modalObserver.disconnect();
      this.modalObserver = null;
    }
  }

  onClose() {
    const event = new CustomEvent('modalClosed', {
      bubbles: true,
      detail: { modalId: this.modalId },
    });
    this.element.dispatchEvent(event);
  }

  removeModalBodyChildren() {
    const modalBody = document.getElementsByClassName('modal-body')[0];
    modalBody.innerHTML = '';
  }

  enableScrolling() {
    document.body.style.overflow = 'auto';
  }

  disableScrolling() {
    document.body.style.overflow = 'hidden';
  }

  isShown() {
    return this.modal && this.modal._isShown;
  }

  makeModalLarge() {
    this.panelTarget.classList.add('modal-lg');
  }

  makeModalSmall() {
    this.panelTarget.classList.remove('modal-lg');
  }

  setTitle(title) {
    if (title === undefined) {
      return;
    }

    this.titleTarget.innerHTML = title;
  }

  showHeader() {
    this.headerTarget.classList.remove('hidden');
  }

  hideHeader() {
    this.headerTarget.classList.add('hidden');
  }

  /**
   *
   * @param type - 'welcome' | 'sessions' | loading
   * @param options
   */
  setupBody(type, options = {}) {
    switch (type) {
      case 'welcome':
        this.removeErrorMessage();
        this.bodyTarget.innerHTML = this.welcomeTarget.innerHTML;
        this.hideHeader();
        break;
      case 'sessions':
        this.removeErrorMessage();
        this.sessionsTarget.firstElementChild.id = 'sessions-modal-body';
        this.bodyTarget.innerHTML = this.sessionsTarget.innerHTML;
        this.walletAddressTarget.innerText = walletShortname(options.address);
        this.hideHeader();
        break;
      case 'loading':
        this.removeErrorMessage();
        this.bodyTarget.innerHTML = this.loadingTarget.innerHTML;
        this.setTitle('');
        break;
    }
  }

  // After loading for a while, stop the loading spinner and give the user an error message
  updateStatus() {
    setTimeout(() => {
      let errorElement = this.bodyTarget.querySelector(`.${ERROR_ICON_CLASS}`);
      if (errorElement) {
        this.showErrorMessage();
      }
    }, 8_000);
  }

  showErrorMessage() {
    this.statusIconTarget.classList.add(ERROR_ICON_CLASS);
    this.statusTextTarget.innerHTML =
      'Loading is taking longer than usual.<br>If your Internet connection is fine, we may be experiencing issues.';
  }

  removeErrorMessage() {
    if (this.statusIconTarget.classList.contains(ERROR_ICON_CLASS)) {
      this.statusIconTarget.classList.remove(ERROR_ICON_CLASS);
      this.statusTextTarget.innerHTML = '';
    }
  }
}
